@import "./../../styles/SharedDefinitions.scss";

/*-------------------------------------------
# Root element
-------------------------------------------*/

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/*-------------------------------------------
# Header & Footer
-------------------------------------------*/

.admin-header {
    background: $primary-color;
    color: #fff;

    .top-bar {
        padding-top: 7px;
        padding-bottom: 7px;

        @media(min-width: 1310px) {
            padding-left: 0 !important;
            padding-left: 0 !important;
        }
    }

    #logo {
        display: inline-block;

        img {
            height: 36px;
        }
    }
}

.admin-login {
    text-align: center;

    .MuiFormControl-root {
        padding-bottom: 20px;
    }

    .MuiTextField-root {
        width: 500px;
        margin: 0 auto;
        display: block;
    }
}

.admin-menu-bar {
    margin-top: 6px;

    >a,
    >span {
        margin-left: 40px;
        color: #eee;
        display: inline-block;
    }

    >a {
        cursor: pointer;

        &:hover {
            color: #c2c2c2;
        }
    }

    .MuiButton-root {
        height: auto;
        padding: 0;
        font: inherit;
        color: inherit;

        svg {
            margin-right: -30px;
        }
    }
}

.admin-menu {
    &.MuiPopover-root {
        .MuiMenu-paper {
            margin-top: 34px;
        }
    }
}

.MuiMenuItem-root {
    &.menu-top {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    svg {
        width: auto;
        height: 18px;
        margin: 0 2px 0 -2px;
        vertical-align: middle;
        transform: translateY(-1px);
    }
}

.admin-footer {
    padding: 15px 0;
    background: #efefef;
    font-size: $smaller-font;
    color: $tertiary-color;

    .MuiContainer-root {
        @media(min-width: 1310px) {
            padding-left: 0 !important;
            padding-left: 0 !important;
        }
    }
}

@media (min-width: 992px) {
    .admin-menu-bar {
        float: right;
    }
}

@media (max-width: 1199px) {
    .configuration-tabs {
        margin-left: 20px;

        li {
            a {
                padding: 5px 20px;
            }
        }
    }

    .footer {
        text-align: center;
    }
}

@media (max-width: 991px) {
    .configuration-tabs {
        width: 100%;
        margin: 15px 0 8px;
    }

    .admin-menu-bar {
        margin: 0 0 4px;
    }
}


/*-------------------------------------------
# Common
-------------------------------------------*/

.admin-links {
    padding: 0;

    li {
        margin-bottom: 15px;
        list-style: none;

        a {
            width: 100%;
            padding: 20px;
            border: 1px solid $secondary-color;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            text-decoration: none;
            display: block;
            transition: all ease 0.25s;

            &:hover,
            &:active,
            &:focus {
                background-color: $secondary-color;
                color: #fff;
            }

            svg {
                margin-right: 1px;
                vertical-align: text-top;
                transform: translateY(-1px);
            }
        }

        @media(min-width: 768px) {
            width: 50%;
            padding-right: 20px;
            margin-bottom: 20px;
            display: inline-flex;
        }

        @media(min-width: 1024px) {
            width: 33.33%;
            display: inline-flex;

            a {
                padding: 30px;
            }
        }
    }
}

.admin-content {
    flex-grow: 1;
    margin-top: 40px;
    margin-bottom: 50px;

    @media(min-width: 1310px) {
        padding-left: 0 !important;
        padding-left: 0 !important;
    }

    h2 {
        font-weight: bold;
        margin-top: $standard-interval;
        margin-bottom: $standard-interval;
    }

    .view-edit-configuration {
        .tabs-container {
            margin-top: -20px;
            margin-bottom: 30px;
            background-color: $secondary-color;
            color: #fff;

            .tab-bar {
                padding: 7px 0;
            }
        }

        .configuration-tabs {
            list-style: none;
            width: auto;
            margin: 0 auto 0 40px;
            padding: 0;
            display: inline-block;

            li {
                display: inline-block;

                div,
                a {
                    padding: 6px 35px;
                    color: #eee;
                    text-decoration: none;
                    cursor: pointer;

                    &:hover {
                        color: #c2c2c2;
                    }
                }

                &.active div,
                a {
                    color: #fff;
                    font-weight: bold;
                }
            }
        }

        .form-group {
            min-height: 45px;

            .MuiGrid-item {
                align-self: center;
            }
        }

        @media (max-width: 599px) {
            .button-block {
                .MuiButton-root {
                    width: 100%;

                    +.MuiButton-root {
                        margin-left: unset;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.filters-bar {
    max-width: unset !important;
}

.MuiDialog-paperScrollPaper {
    padding-top: 48px !important;

    .close-popup-icon {
        top: 12px;
        right: 16px;
    }
}

.configuration-item {
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #dededf;

    a {
        font-weight: 600;
        text-transform: uppercase;
    }
}

.file-item-wrap {
    img {
        background-color: rgba(0, 0, 0, 0.022);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    }

    .date {
        margin-top: 5px;
        padding: 0 15px;
    }
}

.details-box {
    padding: 15px;
    border: 1px solid #dededf;
    border-radius: 4px;

    p:last-child {
        margin-bottom: 0;
    }

    label {
        font-weight: bold;
    }
}

.disabled-checkbox-color {
    color: #8c2980 !important;
}

.disabled-checkbox-label-color {
    .MuiFormControlLabel-label.Mui-disabled {
        color: black !important;
    }
}

.disabled-text-field {
    .Mui-disabled>input {
        background: white;
        color: black;
    }
}

.grid-file-input {
    display: flex;
}

.file-item-wrap {
    margin-right: 12px;
}

.form-group {
    >label+.details-box {
        margin-top: -5px;
    }
}

label:not(.MuiFormControlLabel-root) {
    .MuiSvgIcon-root {
        width: 19px;
        max-height: 19px;
        padding: 2px;
        vertical-align: middle;
        display: inline-block;
        transform: translateY(-1px);
    }
}

label svg {
    margin-left: 2px;
}

.file-item {
    line-height: 1.3;
    display: inline-block;

    svg {
        height: 24px;
        margin-right: 4px;
        vertical-align: middle;
    }

    span {
        vertical-align: middle;
    }
}

.custom-color-picker {
    width: 100%;

    #color-field {
        width: 100%;
    }

    .MuiTextField-root {
        width: calc(100% - 45px);
        display: inline-flex;

        .MuiInputBase-root {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;


            .MuiInputBase-input {
                border-color: transparent;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    #color-displayer-wrap {
        width: 45px;
        height: 45px;
        padding: 10px;
        border: 1px solid #c4c4c4;
        border-left: none;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: #f0f0f0;
        display: inline-flex;
        cursor: pointer;
    }

    #color-displayer {
        width: 25px;
        height: 25px;
        border-radius: 4px;
    }

    #picker {
        position: absolute;
        z-index: 2;

        .chrome-picker {
            font-family: inherit !important;
        }
    }

    #overlay {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
}

.date {
    font-size: 12px;
    color: #919191;
}

.view-edit-configuration {
    @media (min-width: 600px) {
        .align-self-center {
            padding-right: 16px;
        }
    }
}

.admin-form {
    .mandatory-fields-hint {
        color: red;
    }

    &.user-settings-form {
        .mandatory-fields-hint {
            margin-left: 15px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

.tooltip-image {
    width: 100%;
    height: auto;
    margin-top: 8;
}

.heading-example {
    color: #da1f12;
    font-size: 20;
}

.text-link-example {
    color: #da1f12;
    text-decoration: underline;
    cursor: default;
}

.wysiwyg-editor {
    border: 1px solid #bbb;
    border-radius: 4px;
    background: #fff;

    .wysiwyg-toolbar {
        padding: 5px;
        background: #fff;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: 1px solid #bbb;

        button {
            padding: 2px;
            background: none;
            border: none;
            border-radius: 3px;
            transition: 0.2s ease all;

            &:hover,
            &:active {
                background: #f7f7f7;
            }

            .MuiSvgIcon-root {
                width: 28px;
                height: 18px;
            }
        }
    }

    .DraftEditor-root {
        padding: 12px 14px;
    }
}

.pagination {
    margin: 40px 0 10px;
    text-align: center;
    justify-content: center;

    li {
        margin: 0 3px;

        a {
            min-width: 32px;
            padding: 5px;
            line-height: 20px;
            border: 1px solid rgba(128, 128, 128, 0.5);
            border-radius: 4px;
            font-size: 15px;
            text-decoration: none;
            display: block;

            &:hover,
            &:active {
                border-color: $secondary-color;
            }
        }

        &.active {
            a {
                color: #fff;
                background-color: $secondary-color;
                border: none;
                pointer-events: none;
            }
        }

        &.disabled {
            a {
                opacity: 0.25;
                pointer-events: none;
            }
        }
    }
}

@media (min-width: 600px) {
    .admin-form {
        .MuiGrid-grid-sm-4 {
            padding-right: 20px;
        }
    }

    .form-group {
        margin-bottom: 10px !important;

        &.MuiGrid-item {
            margin-bottom: inherit;
        }
    }
}

@media (max-width: 959px) {
    .consent-list-item {
        padding-top: 12px;
        padding-bottom: 12px;

        >.MuiGrid-item {
            padding: 10px !important;
        }
    }
}