$larger-font: 17px;
$medium-font: 15px;
$smaller-font: 13px;

$standard-interval: 20px;
$standard-half-interval: 10px;

$primary-color: #404040;
$secondary-color: #8c2980;
$tertiary-color: #777777;
$cta-color: #ff9843;
$placeholder-color: #d2d1d1;

$primary-background-color: #ffffff;
$secondary-background-color: #f8f2f6;
$disabled-element-background: #e3dee3;

/* make the bootstrap theme customization */
$theme-colors: (
	'primary': $primary-color,
	'secondary': $secondary-color,
	'dark': $tertiary-color,
	'light': $primary-background-color,
	'info': $secondary-background-color,
	'muted': $disabled-element-background,
);

/* import bootstrap after setting theme colors to set changes */
@import '../../node_modules/bootstrap/scss/bootstrap';

/* export variables for use in Typescript */
:export {
	primaryColor: $primary-color;
	secondaryColor: $secondary-color;

	tertiaryColor: $tertiary-color;

	ctaColor: $cta-color;

	primaryBackgroundColor: $primary-background-color;
	secondaryBackgroundColor: $secondary-background-color;
}