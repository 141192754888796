@import "./SharedDefinitions.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css?family=Bitter|Nunito+Sans:300,400,700&display=swap");

@mixin trio($name, $value) {
    -webkit-#{$name}: $value;
    -moz-#{$name}: $value;
    #{$name}: $value;
}

@mixin round-corner($size: 4px) {
    @include trio(border-radius, $size);
}

@mixin translate($x, $y) {
    @include trio(transform, translate($x, $y));
}

@mixin translateX($x) {
    @include trio(transform, translateX($x));
}

@mixin translateY($y) {
    @include trio(transform, translateY($y));
}

@mixin transition($value: 0.25s all ease) {
    @include trio(transition, $value);
}

/*-------------------------------------------
# Common
-------------------------------------------*/

html body {
    min-width: 350px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

::-webkit-input-placeholder {
    color: $placeholder-color;
}

:-ms-input-placeholder {
    color: $placeholder-color;
}

::placeholder {
    color: $placeholder-color;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 28px;
    font-weight: bold !important;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 17px;
}

h6 {
    font-size: 15px;
}

hr {
    &.dashed {
        border-top-style: dashed;
    }

    &.secondary-color {
        border-top-color: $secondary-color;
    }
}

.text-large {
    font-size: 18px;
}

.text-align-left {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.highlight {
    color: $secondary-color;
}

img {
    max-width: 100%;
}

a {
    @include transition;
}

a:hover,
a:active {
    outline: 0 !important;
}

a:focus {
    outline-style: dotted;
    outline-width: 1px;
}

.btn,
input[type="submit"],
html .MuiButton-root {
    min-width: 135px;
    height: 45px;
    @include trio(box-shadow, none);

    .MuiButton-label a {
        text-decoration: none;
    }

    &:hover,
    &:active {
        outline: 0 !important;
        @include trio(box-shadow, none);
    }

    &:focus {
        outline: 1px dotted $primary-color;
    }

    &.cta-btn {
        background-color: $cta-color;

        &:hover,
        &:active {
            background-color: darken($color: $cta-color, $amount: 20);
        }
    }
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
textarea {
    padding: 5px 14px;
    background: #fff;
    border: 1px solid #eee;
    font-size: 15px;
    color: $primary-color;
    @include round-corner;
    @include transition(all 0.25s ease);

    &:focus {
        border: 1px solid $primary-color;
    }
}

textarea {
    padding: 12px 14px;
    border-color: #bbb;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"] {
    height: 45px;
    box-sizing: border-box;
}

input[readonly],
input[disabled],
textarea[disabled],
.form-control[readonly],
.form-control[disabled] {
    border: 1px solid #ccc;
    background: #f9f9f9;
    color: #aaa;
}

.MuiInputBase-root {
    fieldset {
        top: 0;
    }

    legend {
        display: none;
    }
}

label {
    margin-bottom: 4px;
}

.form-group {
    margin-bottom: 20px;
    position: relative;

    &.MuiGrid-item {
        margin-bottom: inherit;
    }
}

.import-table {
    tr, th, td {
        border: 1px solid #bbb;
        padding: 8px;
    }
}

.background-grey {
    background-color: #f7f7f7;
}

.default-round-corner {
    @include round-corner(4px);
}

.close-popup-icon {
    color: #404040;
    opacity: 0.5;
    position: absolute;
    right: 15px;
    cursor: pointer;
}

.comments {
    font-size: 80%;
    color: gray;
    margin: 4px auto;
}

@media (max-width: 991px) {
    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 26px;
    }
}

@media (max-width: 767px) {
    h1 {
        font-size: 25px;
    }
}

@media (max-width: 599px) {
    .w-100-xs {
        width: 100%;
    }

    .text-align-left-xs {
        text-align: left;
    }

    .text-align-center-xs {
        text-align: center;
    }

    .text-align-right-xs {
        text-align: right;
    }
}

/* Page structure */

.page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.page-shadow {
    width: 800px;
    max-width: calc(100% - 32px);
    margin: 16px auto;
    overflow: hidden;
    border: 1px solid #f2f2f2;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(0,0,0,0.2);
}

.header {
    .top-bar {
        min-height: 64px;
        padding: 15px 20px;
        background-position: left center;
        background-repeat: repeat-x;
        background-size: auto 100%;
    }

    #logo {
        display: inline-block

        img {
            max-height: 200px;
        }
    }

    .page-title {
        margin: 20px 0;
        padding-bottom: 10px;
        border-bottom: 1px dashed theme-color("secondary");
    }

    +main {
        .primary-block:first-child {
            padding-top: 30px;
        }
    }
}

.page-top {
    position: relative;
}

.footer {
    padding: 15px 20px;
    background: rgba(128, 128, 128, 0.12);
    font-size: $smaller-font;
    color: $tertiary-color;
}

.footer-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-bottom-custom {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

.error-title,
.error-text {
    color: red;
}

.update-message-text {
    color: #044e86;
}

@media (max-width: 991px) {
    .footer {
        text-align: center;
    }
}

/* Components */

.accreditation-badge {
    margin-bottom: 1em;
    padding: 20px;
    position: relative;

    &.style-2 {
        padding-left: 28px;

        &:before {
            content: "";
            width: 6px;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
        }
    }

    h5 {
        margin-bottom: 3px;
    }

    p {
        margin: 0;
    }
}

.button-block {
    margin-top: 30px;

    div {
        display: inline-block;

        &:not(:first-child) {
            margin-left: 16px;
        }

        button {
            width: 100%;
        }
    }

    button {
        min-width: 145px;

        &:not(:first-child) {
            margin-left: 16px;
        }
    }
}

.buttons {
    .MuiButtonBase-root {
        +.MuiButtonBase-root {
            margin-left: 16px;
        }
    }
}

.card-item {
    padding: $standard-interval 0 $standard-interval 0;
    text-align: center;

    .card-image {
        max-width: 500px;
        max-height: 500px;
        margin: 0 auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include round-corner(50%);

        img {
            max-width: 85%;
            max-height: 85%;
        }
    }

    .card-content {
        padding: 20px;
    }
}

.institution-select-search {
    text-align: left;
    margin-right: 16px;
}

.search-box {
    width: 100%;
    max-width: 100%;
    margin: $standard-interval auto;
    position: relative;

    .MuiInputBase-root {
        width: 100%;
    }

    .MuiInputBase-input {
        border: 1px solid rgba(0, 0, 0, 0.35);
        border-radius: 4px;

        &:hover,
        &:focus {
            border-color: rgba(0, 0, 0, 0.7);
        }
    }

    #keyword-input {
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .MuiSelect-select {
        padding: 10px 14px 8px;
        border-radius: 0;

        .MuiSelect-nativeInput {
            border-radius: 0;

            &:hover,
            &:active,
            &:focus {
                border-radius: 0;
            }
        }
    }

    .fa-search {
        right: 12px;
        opacity: 0.5;
    }

    #search-btn {
        width: 100%;
        min-width: unset;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.consumer-data-right {
    text-align: left;
    padding: 20px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    transition: all 0.25s ease;
    position: relative;
    cursor: pointer;

    h4 {
        font-size: 14px;
    }

    img {
        //display: inline-block;
        float: left;
        margin-right: 6px;
    }
}

.institutions-overflow {
    display: none;
}

.data-holder-selection {
    .data-holder-search-result {
        margin-bottom: 10px;
    }

    .data-holder {
        height: 160px;
        padding: 30px 20px 20px;
        border-radius: 16px;
        background: #fff;
        color: #555;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.25;
        text-align: center;
        text-decoration: none;
        letter-spacing: 0;
        justify-content: center;
        display: flex;
        flex-direction: column;
        transition: all 0.25s ease;
        position: relative;
        cursor: pointer;

        &.cdr:after {
            content: "";
            width: 60px;
            height: 22px;
            position: absolute;
            top: 10px;
            right: 10px;
            background: url("../images/icon-cdr.png") center center no-repeat;
            background-size: contain;
        }

        &:not(.authorised) {
            border: 1px solid rgba(112, 112, 112, 0.45);
        }

        &.authorised {
            border-width: 2px;
            border-style: solid;

            .fa-check-circle {
                color: #080;
                font-size: 18px;
                right: 10px;
                bottom: 10px;
                position: absolute;
            }
        }

        &:hover,
        &:active {
            border-color: rgba(112, 112, 112, 0.9);
            color: #000;
        }

        .data-holder-logo {
            width: 68%;
            height: 55px;
            margin: 0 auto;
            background: transparent center center no-repeat;
            background-size: contain;
            display: inline-block;
        }

        p {
            margin: 16px 0 0;
            font: inherit;
        }
    }
}

.data-request {

    .error-title,
    .error-text {
        color: red;
    }
}

.data-request-card {
    border-radius: 4px;

    >label {
        width: 100%;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: currentColor;
    }

    .data-list-container {
        padding-top: $standard-interval;
        transition: height 0.25s;

        .data-list-toggle {
            font-weight: bold;
            cursor: pointer;

            >svg {
                vertical-align: -0.3em;
            }
        }

        .data-list {
            margin-top: 5px;
            padding-left: 24px;

            &.hidden {
                display: none;
            }
        }
    }

    .mandatory-field-indicator {
        color: red;
        font-weight: normal;
    }
}

.customer-details-inputs {
    .mandatory-fields-hint {
        padding-left: 20px;
        text-align: left;
        color: red;
    }
}

.data-request {
    h3 {
        margin-top: 40px;
        font-weight: 400;
    }

    .mandatory-fields-info,
    .mandatory-fields-hint {
        color: red;
    }
}

.bank-feeds-request {
    h2 {
        text-align: center;
    }

    iframe {
        width: 100%;
        margin-top: 0;
        background: #f9f9f9;
        border: 0 none;
        min-height: 200px;
        height: 70vh;
    }
}

.highlight-block {
    box-sizing: border-box;
    border: 1px solid $tertiary-color;
    margin: $standard-interval 0;
    padding: $standard-interval;
    border: 1px solid rgba(0, 0, 0, 0.2);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
    }

    .MuiFormControl-root {
        width: 345px;
        max-width: 100%;

        .MuiSelect-select {
            padding: 15px 15px 14px;
        }
    }
}

.additional-use-choices {
    box-sizing: border-box;
    border: 1px solid #aaa;
    border-radius: 4px;

    h5 {
        font-weight: bold;
        margin-bottom: $standard-interval;
    }

    h6 {
        font-weight: normal;
        max-width: 504px;
    }

    .item {
        margin-bottom: 0;

        label {
            width: 100%;
        }
    }
}

.navigation-bar {
    text-align: left;
    font-size: 16px;
    font-weight: bold;

    a {
        padding: 2px 0;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        svg {
            margin-right: 4px;
        }
    }
}

.primary-block {
    padding-top: 50px;
    padding-bottom: 50px;
}

.secondary-block {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    h2 {
        width: 100%;
        margin: 15px auto;
        text-align: center;
    }

    h3 {
        margin: $standard-half-interval 0 $standard-half-interval 0;
        font-weight: 600;
        font-size: 2em;
    }

    .cta {
        margin-top: 20px;

        &:only-child {
            margin-top: unset;

            .button-block:only-child {
                margin-top: unset;
            }
        }
    }
}

$switchSize: 1.5em;
$switchHeight: $switchSize;
$switchWidth: $switchSize * 1.7;
$switchBorder: $switchSize * 0.085;

.switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked+.switch-background {
        .switch-button {
            left: calc(100% - 2px);
            transform: translateX(-100%);
        }
    }
}

.switch-background {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: $switchWidth;
    height: $switchHeight;
    background: $disabled-element-background;
    border-radius: $switchWidth;
    position: relative;
    transition: background-color 0.25s;

    .switch-button {
        content: "";
        position: absolute;
        top: $switchBorder;
        left: $switchBorder;
        width: $switchHeight - $switchBorder * 2;
        height: $switchHeight - $switchBorder * 2;
        border-radius: $switchHeight - $switchBorder * 2;
        transition: 0.25s;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }
}

.switch-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    >* {
        padding: 0 $standard-half-interval;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            margin-top: 0.1em;
            margin-left: auto;

            &:hover,
            &:active {
                outline: 0 !important;
            }

            &:focus {
                outline: 1px dotted $primary-color;
                outline-offset: 1px;
            }
        }
    }
}

@media (max-width: 599px) {
    .button-block {
        button,
        div {
            &:not(:only-child) {
                width: calc(50% - 8px);
                max-width: initial;
                min-width: initial;
                white-space: nowrap;
                overflow: hidden;

                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }
    }

    .data-holder-selection {
        .data-holder {
            font-size: 13px;

            &.cdr:after {
                width: 46px;
                height: 18px;
                top: 8px;
                right: 8px;
            }

            &.authorised {
                .fa-check-circle {
                    right: 8px;
                    bottom: 8px;
                }
            }

            .data-holder-logo {
                width: 92%;
                height: 45px;
            }
        }
    }
}

@media (max-width: 374px) {
    .button-block {
        button,
        div {
            letter-spacing: -0.5px;
        }
    }
}

/* Dashboard */

.dashboard-action-bar {
    margin: 30px 0;
}

.breadcrumbs {
    margin: 10px 0;
    font-size: 14px;
    color: #afafaf;
    text-align: left;

    a {
        color: inherit;
        text-decoration: none;

        +a,
        +span {
            &:before {
                content: "/";
                margin: 0 10px;
                color: #afafaf;
                display: inline-block;
            }
        }

        &:hover {
            color: $primary-color;
            text-decoration: underline;
        }
    }
}

.MuiBreadcrumbs-ol {
    display: block !important;

    .MuiBreadcrumbs-li,
    .MuiBreadcrumbs-separator {
        display: inline;
    }
}

.filters-bar {
    width: 100%;
    margin: 0 0 0 auto;
    position: relative;

    #keyword-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .MuiFormControl-root {
        width: 100%;

        fieldset {
            border-left-width: 0;
            border-right-width: 0;
            border-radius: 0;
        }
    }

    .MuiInputBase-root,
    .MuiInputBase-input {
        width: 100%;
    }

    #keyword-input {
        border: 1px solid rgba(0, 0, 0, 0.3);

        &:focus {
            border-color: rgba(0, 0, 0, 0.7);
        }
    }

    .MuiFormControl-root:hover,
    .Mui-focused {
        fieldset {
            border: 1px solid rgba(0, 0, 0, 0.3) !important;
            width: calc(100% + 2px);
            margin: 0 -1px;
        }
    }

    #section {
        border-radius: 0;
    }

    .MuiButton-root {
        width: 100%;
        min-width: unset;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.table-head {
    background: rgba(0, 0, 0, 0.05);
    color: #969696;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;

    >.MuiGrid-item {
        padding: 14px 10px;
    }
}

.consent-list-item {
    border-bottom: 1px solid #d3d3d3;

    &:first-child {
        border-top: 1px solid #d3d3d3;
    }

    >.MuiGrid-item {
        padding: 22px 10px;
    }

    .col-actions {
        padding-right: 0;
        position: relative;

        a {
            text-decoration: none;
            cursor: pointer;

            &:hover,
            &:active {
                opacity: 0.75;
            }

            .fa-chevron-right {
                margin-left: 2px;
                vertical-align: middle;
            }
        }
    }

    img {
        max-width: 100%;
        max-height: 45px;
    }

    .MuiLink-root:hover {
        text-decoration: none;
    }

    img.consent-list-item-is-cdr {
        margin: 0 !important;
    }
}

.status-active,
.status-pending,
.status-withdrawn,
.status-expired,
.status-archived .status-inactive {
    font-weight: bold;
    text-transform: capitalize;

    svg {
        width: auto;
        height: 14px;
        margin-right: 2px;
        vertical-align: middle;
        @include translateY(-1px);
    }
}

.status-active {
    color: #60cd93;
}

.status-pending {
    color: #eaa94a;
}

.status-inactive,
.status-withdrawn,
.status-expired,
.status-archived {
    color: #bebebe;
}

.message-box {
    width: 100%;
    padding: 23px 25px 23px 55px;
    border-radius: 4px;
    text-align: left;
    position: relative;

    #icon {
        left: 18px;
        top: 20px;
        position: absolute;

        svg {
            width: 28px;
        }
    }
}

.pager {
    margin: 40px 0 10px;
    text-align: center;
}

@media (min-width: 600px) {
    .consent-list-item {
        img {
            margin: 0 auto;
            display: block;
        }
    }
}

@media (min-width: 960px) {
    .filters-bar {
        max-width: 640px;
    }

    .consent-list-item {
        img {
            margin: 0 auto;
            display: block;
        }
    }
}

@media (max-width: 899px) {
    .dashboard-action-bar {
        flex-direction: column-reverse;
    }

    .filters-bar {
        margin: 0 auto 20px;
    }

    .consent-list-item {
        .col-name {
            margin-bottom: 10px;
        }

        .col-date {
            margin-top: 10px;
        }

        .col-actions {
            a {
                font-size: 22px;
                display: inline-block;
                right: 0;
                top: 50%;
                position: absolute;
                @include translateY(-50%);
            }
        }
    }
}

/* Consent details */

.consent-details-header {
    margin-bottom: 35px;
    padding-bottom: 25px;
    border-bottom: 1px dashed $secondary-color;

    .institution-logo {
        height: 50px;

        span {
            display: inline-block;
            vertical-align: middle;
            height: 100%;
        }

        img {
            vertical-align: middle;
        }
    }

    .institution-title {
        padding-left: 20px;
    }

    .MuiButtonBase-root {
        min-width: 150px;
        margin-left: 1em;
        margin-bottom: 12px;
    }

    @media (max-width: 599px) {
        .MuiButtonBase-root {
            width: calc(50% - 8px);
            min-width: unset;
            margin-left: unset;
            margin-bottom: unset;

            &:first-child {
                margin-right: 16px;
            }
        }
    }
}

.institution-card {
    h2 {
        margin-bottom: 5px;
        font-size: 26px;
    }

    #card-misc {
        span+span {
            margin-left: 30px;
        }
    }
}

.data-collection-details-padding {
    padding-top: 30px;
}

.margin-top-on-data-delete-confirm {
    margin-top: 30px;
}

.withdraw-success-msg-margin {
    margin-bottom: 0px;
}

.customer-details-input-section {
    text-align: center;

    .customer-details-inputs {
        margin-top: 20px;

        >div {
            padding: 10px 0;
        }

        .MuiInputBase-root {
            display: block;
        }

        .MuiInputBase-input {
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 4px;

            &:hover,
            &:focus {
                border-color: rgba(0, 0, 0, 0.7);
            }
        }

        button {
            margin-top: 10px;
        }
    }
}

html .MuiSelect-select {
    padding: 11px 14px 9px;
    font-size: 15px;
}

.MuiAccordion-root {
    .MuiSvgIcon-root {
        width: 32px;
        height: 32px;
    }
}

.MuiAccordionDetails-root {
    flex-direction: column;
}

html .styled-accordion {
    margin: 13px 0 !important;
    padding: 0 0 0 4px;
    border: 1px solid #bebebe;
    border-radius: 4px;

    &:before {
        display: none;
    }

    .MuiAccordionSummary-root {
        font-size: 16px;
        font-weight: bold;

        &.Mui-expanded {
            min-height: unset;
        }
    }

    .MuiAccordionSummary-content {
        &.Mui-expanded {
            margin: 12px 0;
        }
    }

    .MuiIconButton-edgeEnd {
        margin-right: -18px;
    }
}

html .simple-accordion {
    margin-bottom: 13px;
    background: none;
    color: inherit;

    &:before {
        content: none;
    }

    .MuiAccordionSummary-root {
        min-height: unset;
        margin-bottom: 10px;
        font-size: 22px;

        .MuiIconButton-root {
            padding: 0;
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;
    }

    .MuiAccordionSummary-root,
    .MuiAccordionDetails-root {
        padding-left: 0;
        padding-right: 0;
    }

    .MuiIconButton-edgeEnd {
        margin-right: -8px;
    }
}

.go-back-link {
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;

    svg {
        margin-right: 7px;
    }
}

.go-back-data-holder-selection {
    float: left;
}

@media (max-width: 959px) {
    .institution-card {
        h2 {
            font-size: 22px;
        }

        #card-misc {
            span:nth-child(3) {
                margin: 5px 0 0;
                display: block;
            }
        }
    }
}

@media (max-width: 599px) {
    .institution-card {
        #card-misc {
            span+span {
                margin-left: 24px;
            }
        }
    }
}

/* Consent history */

.consent-history-list {
    text-align: center;
}

.consent-history-list-item {
    border-bottom: 1px solid #d3d3d3;

    >.MuiGrid-item {
        padding: 22px 10px;
    }

    .col-actions {
        padding-right: 0;
        position: relative;

        a {
            text-decoration: none;
            cursor: pointer;

            &:hover,
            &:active {
                opacity: 0.75;
            }

            .fa-chevron-right {
                margin-left: 2px;
                vertical-align: middle;
            }
        }
    }

    .MuiLink-root,
    .MuiLink-root:hover {
        text-decoration: none;
    }
}

@media (max-width: 959px) {
    .consent-history-list-item {
        text-align: left;

        >.MuiGrid-item {
            padding: 20px 0;
        }

        div[class*="col-"] {
            &:not(.col-actions) {
                min-height: 33px;
                padding: 7px 5px 7px 50%;
                position: relative;

                &:before {
                    width: 50%;
                    padding-right: 20px;
                    color: #969696;
                    font-size: 12px;
                    font-weight: bold;
                    text-transform: uppercase;
                    left: 0;
                    top: 9px;
                    position: absolute;
                    display: block;
                }
            }

            &.col-update-date:before {
                content: "Update date";
            }

            &.col-description:before {
                content: "Change description";
            }

            &.col-granted-date:before {
                content: "Consent granted date";
            }

            &.col-expiry-date:before {
                content: "Expiry date";
            }
        }

        .col-actions {
            position: relative;

            a {
                font-size: 22px;
                line-height: 22px;
                display: inline-block;
                right: 0;
                top: 50%;
                position: absolute;
                @include translateY(-60%);
            }
        }
    }
}

/* Utilities */

.v-center {
    top: 50%;
    position: absolute;
    @include translateY(-50%);
}

.left-center {
    @extend .v-center;
    left: 0;
}

.right-center {
    @extend .v-center;
    right: 0;
}

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-0 {
    padding-bottom: 0;
}

.pb-10 {
    padding-bottom: 10px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-50 {
    padding-bottom: 50px;
}

/* Overwritings */

html .MuiTypography-body1 {
    font: inherit;
}

html .MuiMenu-paper {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.typo-display {
    display: flex;
}

.data-list-toggle {
    svg {
        margin-left: 3px;
    }
}

.MuiPopover-paper {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    background-color: #fff !important;
}

.MuiDialog-paper {
    padding: 22px;

    h2 {
        margin-bottom: 20px;
    }

    .MuiDialogContent-root {
        padding: 0 24px 24px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    .MuiDialogActions-root {
        justify-content: center;
    }

    .MuiDialogActions-spacing> :not(:first-child) {
        margin-left: 15px;
    }

    .background-grey {
        >div+div {
            margin-top: 20px;
        }
    }
}

.MuiSelect-root.MuiInputBase-input {
    padding: 13px 14px;
}

html .MuiSelect-selectMenu {
    height: 45px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: none;

    &:focus {
        background: #fff;
    }
}

.MuiPopover-root .MuiMenuItem-root.Mui-selected {
    background-color: rgba(128, 128, 128, 0.15);
}

.switch-content>* {
    padding: 0 12px;
}

.switch-background {
    margin-top: 0 !important;
    @include translateY(-1px);
}

html .MuiBreadcrumbs-root {
    margin: 12px 0;
    font-size: 14px;
    color: #afafaf;

    a:not(:hover) {
        color: inherit;
        text-decoration: none;
    }
}

.MuiAutocomplete-inputRoot {
    padding: 5px 45px 5px 14px;

    .MuiAutocomplete-input {
        height: 27px;
        padding: 0 5px !important;
        border: none !important;
    }
}

.MuiAutocomplete-popper {
    margin-left: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}

@media (max-width: 1279px) {
    .accreditation-badge {
        .MuiGrid-grid-lg-8 {
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 991px) {
    .cdr-value-proposition #primary-content-2 {
        margin-top: 30px;
    }
}

@media (max-width: 575px) {
    .MuiDialog-paper {
        margin: 15px;
        padding: 12px;

        .MuiDialogContent-root {
            text-align: center;
            padding: 12px;
        }

        .MuiDialogActions-root {
            padding: 12px;
            display: block;

            .MuiButtonBase-root {
                width: 100%;
                margin: 0 auto;

                +.MuiButtonBase-root {
                    margin-top: 16px;
                }
            }
        }
    }
}

.multibank-confirmation {
    text-align: center;
}

.multibank-list {
    text-align: left;
}

.notice-box {
    text-align: left;
    border: 1px solid #b22222;
    margin-top: 13px;
    margin-bottom: 13px;
    padding: 16px 30px;
    border-radius: 4px;
    color: #b22222;
}

.notice-box p {
    margin: 0;
}

.multibank-selection {
    border: 1px solid #bebebe;
    margin-top: 13px;
    margin-bottom: 13px;
    padding: 30px;
    border-radius: 4px;

    .institution-container {
        padding-bottom: 10px;

        .institution-logo {
            display: inline-block;
            vertical-align: middle;
            text-align: center;

            img {
                height: 40px;
                object-fit: contain;
            }
        }

        .institution-name {
            display: table;
            padding-left: 20px;
            padding-right: 20px;
            vertical-align: middle;
            height: 40px;

            p {
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    .institution-container:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    @media (max-width: 599px) {
        padding: 20px;
    }
}

.success-confirmation {
    border: 1px solid #bebebe;
    padding: 50px !important;
    border-radius: 4px;
}

.loading-spinner {
    .spinner-content {
        text-align: center;

        h4 {
            margin: 7px auto 0;
            font-size: 18px;
        }
    }

    &.inline-block {
        display: inline-block;
    }

    &.fixed,
    &.absolute {
        width: 100%;
        left: 0;
        top: 0;
        z-index: 9999;

        .MuiCircularProgress-root {
            color: currentColor;
        }

        .spinner-content {
            max-width: 240px;
            @extend .v-center;
            left: 50%;
            @include translate(-50%, -50%);
        }
    }

    &.fixed {
        height: 100vh;
        position: fixed;
    }

    &.absolute {
        height: 100%;
        position: absolute;
    }

    &.overlay {
        background: rgba(0, 0, 0, 0.15);
    }
}

.page-wrapper {
    .header+main {
        .loading-spinner.fixed {
            height: 100vh;
            top: 0;
        }
    }
}

.paginated-table {
    min-height: 45px;
    position: relative;

    .loading-spinner {
        margin-top: 20px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.console-log {
    background-color: #e0e0e0;
    width: 800px;
    padding: 5px;
    border: 1px solid black;
}

.broker-signup-header {
    .top-bar {
        padding-top: 7px;
        padding-bottom: 7px;

        @media(min-width: 1310px) {
            padding-left: 0 !important;
            padding-left: 0 !important;
        }
    }

    #logo {
        display: inline-block;
        margin: 20px 0;

        img {
            height: 75px;
        }
    }
}

.broker-signup-banner {
    .container {
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 50px;
        padding-bottom: 50px;

        h2 {
            text-align: left;
            font-family: 'Bitter', serif;
            font-weight: 300;
            line-height: 1.4em;
            margin: 20px 40px 30px 0;
            font-size: 36px;
            color: rgb(255, 255, 255)
        }

        .button-block {
            text-align: left;
        }
    }
}

.demonstration {
    text-align: center;
    padding: 80px 0 85px;
    font-family: "Nunito Sans", Calibri, sans-serif;

    h2 {
        font-weight: 300;
        line-height: 1.4em;
        margin: 20px 0px 30px 0;
        font-size: 36px;
    }

    .features {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        h2 {
            text-align: left;
        }

        p {
            text-align: left;
            font-size: 20px;
            font-weight: 300;
        }

        .card-content {
            p {
                text-align: center;
                font-size: 20px;
                font-weight: 300;
            }

            strong {
                font-size: 24px !important;
                font-weight: 500 !important;
            }
        }

        .card-image img {
            max-width: 100px;
        }
    }
}

.explanation {
    background-color: #edeced;
    text-align: center;
    padding: 80px 0 40px;

    .primary-block {
        padding-bottom: 0;
    }

    .card-content {
        p {
            font-size: 20px;
            font-weight: 300;
        }
    }
}

.security {
    h2 {
        font-weight: 300;
        line-height: 1.4em;
        font-size: 36px;
    }

    .security-info {
        ul {
            padding: 0;
            list-style: none;
        }

        li {
            padding-left: 0;
            position: relative;
            font-size: 1.1em;

            svg {
                color: #FF8818;
                margin-right: 10px;
            }
        }

    }
}


.questions {
    background-color: #cccccc;
    text-align: center;
    padding: 80px 0 40px;

    h2 {
        font-weight: 300;
        line-height: 1.4em;
        font-size: 36px;
    }
}

.trusted-adviser-recover-dashboard {
    text-align: center;

    .MuiFormControl-root {
        padding-bottom: 20px;
    }

    .MuiTextField-root {
        width: 500px;
        margin: 0 auto;
        display: block;
    }
}